import React from "react"
import Layout from "../components/layout"
import StyledHeaderImage from "../components/header-image"

const AboutPage = () => (
  <Layout>
    <StyledHeaderImage page="about" text="Meet Our Team" />
  </Layout>
)

export default AboutPage
