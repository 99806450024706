import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const TextContainer = styled.div`
  text-align: center;
  color: white;
  padding: 10% 0;
`

const BigText = styled.h1`
  font-size: 4rem;
  font-weight: 300;
`

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
`

const HeaderImage = ({page, text}) => {
  const data = useStaticQuery(graphql`
    query {
      contact: file(relativePath: { eq: "office people.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 4160) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      about: file(relativePath: { eq: "bridge.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 4160) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      faq: file(relativePath: { eq: "thinking.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 4160) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      forms: file(relativePath: { eq: "writing.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 4160) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  
  return (
    <BackgroundImage fluid={data[page].childImageSharp.fluid}>
      <Overlay>
        <TextContainer>
          <BigText>{text}</BigText>
        </TextContainer>
      </Overlay>
    </BackgroundImage>
  )
}

const StyledHeaderImage = styled(HeaderImage)`
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default StyledHeaderImage
